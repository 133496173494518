<template>
    <Dialog modal v-bind:visible="visible" header="プロフィール" :style="{ width: '40vw' }" :breakpoints="{ '960px': '75vw', '641px': '100vw' }"
        v-on:update:visible="onVisible" :draggable="draggable" >
        <div id="top" class="mx-auto w-full max-w-screen-xl">

            <div class="my-12 p-4 border-2" v-html="profileHtml">
            </div>

        </div>
    </Dialog>
</template>

<script>
    import Dialog from 'primevue/dialog';

    import { marked } from 'marked';

    export default {
        components: {
            Dialog
        },
        props: {
            visible: {
                type: Boolean,
                default: false
            },
            draggable: {
                type: Boolean,
                default: false
            }
        },
        data() {
            return {
                profileHtml: null
            }
        },
        mounted() {
            const me = this
            fetch('./assets/portal/profile.md')
                .then(response => response.text())
                .then(data => {
                    me.profileHtml = marked.parse(data);
                });
        },
        emits: ['change-visible'],
        methods: {
            /**
             * "表示切替"イベント時処理
             */
            onVisible(visible) {
                this['$emit']('change-visible', visible);
            }
        }
    };
</script>

<style scoped>
</style>