<template>
    <Dialog modal v-bind:visible="visible" header="当サイトについて" :style="{ width: '40vw' }" :breakpoints="{ '960px': '75vw', '641px': '100vw' }"
        v-on:update:visible="onVisible" :draggable="draggable" >
        <div id="top" class="mx-auto w-full max-w-screen-xl">

            <div class="my-12 p-4 border-2" v-html="applicationHtml">
            </div>

            <div class="card flex justify-content-end gap-2 py-2">
                <span class="mt-2">Share：</span>
                <Button class="bg-surface-0 hover:bg-bluegray-100 border-surface-0 p-1" text @click="onX">
                    <img alt="X" src="/assets/image/sns/sns_x.png" height="24" width="24" />
                </Button>
                <Button class="bg-surface-0 hover:bg-bluegray-100 border-surface-0 p-1" text @click="onFacebook">
                    <img alt="facebook" src="/assets/image/sns/sns_facebook.png" height="24" width="24" />
                </Button>
            </div>

            <div>バージョン番号：{{ version }}</div>
            <div>{{ copyright }}</div>
        </div>
    </Dialog>
</template>

<script>
    import Dialog from 'primevue/dialog';
    import Button from 'primevue/button';

    import HtmlUtils from "@/app/util/html-utils.mjs";

    import { marked } from 'marked';

    export default {
        components: {
            Dialog,
            Button
        },
        props: {
            visible: {
                type: Boolean,
                default: false
            },
            draggable: {
                type: Boolean,
                default: false
            }
        },
        data() {
            return {
                applicationHtml: null,
                version: this.$Settings.version,
                copyright: this.$Settings.copyright
            }
        },
        mounted() {
            const me = this
            fetch('./assets/portal/application.md')
                .then(response => response.text())
                .then(data => {
                    me.applicationHtml = marked.parse(data);
                });
        },
        emits: ['change-visible'],
        methods: {
            /**
             * "表示切替"イベント時処理
             */
            onVisible(visible) {
                this['$emit']('change-visible', visible);
            },

            /**
             * "X"イベント時処理
             */
            onX() {
                HtmlUtils.movePage(this.$Settings.sns.x);
            },

            /**
             * "Facebook"イベント時処理
             */
            onFacebook() {
                HtmlUtils.movePage(this.$Settings.sns.facebook);
            }
        }
    };
</script>

<style scoped>
</style>