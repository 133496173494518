<template>
    <div class="menubar-panel" v-if="visible">
        <ExMenubar :model="menuItems" class="gap-2">
            <template #start>
                <slot name="start"></slot>
            </template>
            <template #end>
                <slot name="end"></slot>
            </template>
        </ExMenubar>
    </div>
</template>

<script>
    import ExMenubar from "@/overrides/frame/ExMenubar"

    export default {
        components: {
            ExMenubar
        },
        props: {
            visible: {
                type: Boolean,
                default: true
            },
            menuItems: {
                type: Array
            }
        }
    };
</script>

<style scoped>
    .menubar-panel {
        width: 100%;
        height: 60px;
        margin: 0px !important;
        padding: 0px !important;
    }
</style>
